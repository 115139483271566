import produce from "immer";
import { get } from "lodash";
import { Reducer } from "redux";

import {
  activateSubscription,
  calculateProRata,
  changeSubscription,
  checkout,
  endSubscription,
  fetchFlowPlans,
  getActiveCampaignSubscription,
  getActiveOrganisationSubscription,
  getAdViews,
  getAllUserCampaignSubscriptions,
  getUserSubscriptionByCampaignId,
  postToPage,
  patchSubscription,
  setFlowPlansBillingFrequency,
  setProRata,
  setSelectedFlowPlan,
  createSubscription,
  masterCampaignCheckout,
} from "./routines";
import { SubscriptionState } from "./types";

const initialState: SubscriptionState = {
  listloading: false,
  adViews: undefined,
  adViewsLoading: false,
  flowPlans: [],
  totalRecords: 0,
  flowPlanloading: false,
  subscriptionsLoading: false,
  subscription: undefined,
  userSubscriptions: [],
  checkoutLoading: false,
  errors: undefined,
  changingSubscription: false,
  proRata: undefined,
  calculatingProRata: false,
  billingFrequency: undefined
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    //#region  Trigger
    case getAdViews.TRIGGER: {
      return { ...state, adViewsLoading: true, errors: undefined };
    }
    case fetchFlowPlans.TRIGGER: {
      return { ...state, listloading: true, errors: undefined };
    }

    case setSelectedFlowPlan.TRIGGER: {
      return { ...state, flowPlanloading: true, errors: undefined };
    }

    case checkout.TRIGGER: {
      return { ...state, checkoutLoading: true, errors: undefined };
    }
    case postToPage.TRIGGER: {
      return { ...state, checkoutLoading: true, errors: undefined };
    }

    case endSubscription.TRIGGER: {
      return { ...state, subscriptionsLoading: true, errors: undefined };
    }

    case activateSubscription.TRIGGER: {
      return { ...state, subscriptionsLoading: true, errors: undefined };
    }

    case getActiveCampaignSubscription.TRIGGER: {
      return { ...state, subscriptionsLoading: true, errors: undefined };
    }

    case getAllUserCampaignSubscriptions.TRIGGER: {
      return { ...state, subscriptionsLoading: true, errors: undefined };
    }

    case getUserSubscriptionByCampaignId.TRIGGER: {
      return { ...state, subscriptionsLoading: true, errors: undefined };
    }

    case changeSubscription.TRIGGER: {
      return { ...state, changingSubscription: true, errors: undefined };
    }

    case setFlowPlansBillingFrequency.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case calculateProRata.TRIGGER: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.calculatingProRata = true;
        draft.errors = undefined;
      });
      return newDerivedState;
    }

    case createSubscription.TRIGGER: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.subscriptionsLoading = true;
        draft.errors = undefined;
      });
      return newDerivedState;
    }

    case masterCampaignCheckout.TRIGGER: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.checkoutLoading = true;
        draft.errors = undefined;
        draft.masterCampaignCheckout = undefined;
      });
      return newDerivedState;
    }

    //#endregion

    //#region  Success
    case getAdViews.SUCCESS: {
      const adViews = get(action, "payload.adViews");
      return {
        ...state,
        adViews,
      };
    }
    case fetchFlowPlans.SUCCESS: {
      const flowPlans = get(action, "payload.flowPlans", []);
      const totalRecords = get(action, "payload.count", 0);
      return {
        ...state,
        flowPlans,
        totalRecords
      };
    }

    case setSelectedFlowPlan.SUCCESS: {
      const deletedFlag = get(action, "payload.isDeleted", false);
      if (deletedFlag) {
        return {
          ...state,
          selectedFlowPlan: undefined,
        };
      } else {
        const selectedFlowPlan = get(action, "payload.plan", undefined);
        return {
          ...state,
          selectedFlowPlan,
        };
      }
    }

    case checkout.SUCCESS: {
      return { ...state };
    }
    case postToPage.SUCCESS: {
      return { ...state };
    }

    case endSubscription.SUCCESS: {
      return { ...state };
    }

    case activateSubscription.SUCCESS: {
      return { ...state };
    }

    case getActiveCampaignSubscription.SUCCESS: {
      const activeSubscription = get(action, "payload.subscription", undefined);
      return {
        ...state,
        activeSubscription,
      };
    }

    case getAllUserCampaignSubscriptions.SUCCESS: {
      const userSubscriptions = get(action, "payload", undefined);
      return {
        ...state,
        userSubscriptions,
      };
    }

    case getActiveOrganisationSubscription.SUCCESS: {
      const activeOrganisationSubscription = get(action, "payload", undefined);
      return {
        ...state,
        activeOrganisationSubscription,
      };
    }

    case getUserSubscriptionByCampaignId.SUCCESS: {
      const subscription = get(action, "payload", undefined);
      return {
        ...state,
        subscription,
      };
    }

    case changeSubscription.SUCCESS: {
      return { ...state };
    }

    case setFlowPlansBillingFrequency.SUCCESS: {
      const billingFrequency = get(action, "payload");
      return { ...state, billingFrequency };
    }

    case calculateProRata.SUCCESS:
    case setProRata.SUCCESS: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.proRata = action.payload;
      });
      return newDerivedState;
    }

    case patchSubscription.SUCCESS: {
      const newDerivedState = produce<SubscriptionState>(
        state,
        (draft: SubscriptionState) => {
          Object.assign(draft, { ...action.payload });
        }
      );

      return newDerivedState;
    }

    case createSubscription.SUCCESS: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.subscription = action.payload;
        draft.selectedFlowPlan = get(action, 'payload.product');
      });
      return newDerivedState;
    }

    case masterCampaignCheckout.SUCCESS: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.masterCampaignCheckout = action.payload;
      });
      return newDerivedState;
    }

    //#endregion

    //#region  Failure
    case getAdViews.FAILURE: {
      return {
        ...state,
        adViews: undefined,
        errors: action.payload,
        adViewsLoading: false,
      };
    }

    case fetchFlowPlans.FAILURE: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case checkout.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }
    case postToPage.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case endSubscription.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case activateSubscription.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case getActiveCampaignSubscription.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case getAllUserCampaignSubscriptions.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case getActiveOrganisationSubscription.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case getUserSubscriptionByCampaignId.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        subscriptionsLoading: false,
      };
    }

    case changeSubscription.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        changingSubscription: false,
      };
    }

    case calculateProRata.FAILURE:
    case createSubscription.FAILURE:
    case masterCampaignCheckout.FAILURE: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.errors = action.payload;
      });
      return newDerivedState;
    }

    //#endregion

    //#region  Fulfill
    case fetchFlowPlans.FULFILL: {
      return {
        ...state,
        listloading: false,
      };
    }
    case getAdViews.FULFILL: {
      return {
        ...state,
        adViewsLoading: false,
      };
    }

    case setSelectedFlowPlan.FULFILL: {
      return {
        ...state,
        flowPlanloading: false,
      };
    }

    case checkout.FULFILL: {
      return {
        ...state,
        checkoutLoading: false,
      };
    }
    case postToPage.FULFILL: {
      return {
        ...state,
        checkoutLoading: false,
      };
    }

    case endSubscription.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case activateSubscription.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case getActiveCampaignSubscription.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case getAllUserCampaignSubscriptions.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case getActiveOrganisationSubscription.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case getUserSubscriptionByCampaignId.FULFILL: {
      return {
        ...state,
        subscriptionsLoading: false,
      };
    }

    case changeSubscription.FULFILL: {
      return {
        ...state,
        changingSubscription: false,
      };
    }

    case calculateProRata.FULFILL: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.calculatingProRata = false;
      });
      return newDerivedState;
    }

    case createSubscription.FULFILL: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.subscriptionsLoading = false;
      });
      return newDerivedState;
    }

    case masterCampaignCheckout.FULFILL: {
      const newDerivedState = produce<SubscriptionState>(state, (draft: SubscriptionState) => {
        draft.checkoutLoading = false;
      });
      return newDerivedState;
    }

    //#endregion

    default: {
      return state;
    }
  }
};

export { reducer as subscriptionReducer };
