import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { CalculateProRataPayload, CreateSubscriptionPayload, FetchFlowPlansPayload, FetchFlowPlansResponse, GetAdViewsPayload, MasterCampaignCheckoutPayload, SubscriptionState } from "./types";

export const getAdViews = createRoutine<GetAdViewsPayload>("@@subscriptions/GET_ADVIEWS");
export const getAdViewsPromise: (payload: GetAdViewsPayload) => Promise<any> = promisifyRoutine(getAdViews);

export const fetchFlowPlans = createRoutine<FetchFlowPlansPayload>("@@subscriptions/FETCH_FLOW_PLANS");
export const fetchFlowPlansPromise: (payload: FetchFlowPlansPayload) => Promise<FetchFlowPlansResponse> = promisifyRoutine(fetchFlowPlans);

export const setSelectedFlowPlan = createRoutine(
  "@@subscriptions/SET_SELECTED_FLOW_PLAN"
);
export const setSelectedFlowPlanPromise = promisifyRoutine(setSelectedFlowPlan);

export const checkout = createRoutine("@@subscriptions/CHECKOUT");
export const checkoutPromise = promisifyRoutine(checkout);

export const postToPage = createRoutine("@@subscriptions/POST_TO_PAGE");
export const postToPagePromise = promisifyRoutine(postToPage);

export const endSubscription = createRoutine(
  "@@subscriptions/END_SUBSCRIPTION"
);
export const endSubscriptionPromise = promisifyRoutine(endSubscription);

export const activateSubscription = createRoutine(
  "@@subscriptions/ACTIVE_SUBSCRIPTION"
);
export const activateSubscriptionPromise =
  promisifyRoutine(activateSubscription);

export const getActiveCampaignSubscription = createRoutine(
  "@@subscriptions/GET_ACTIVE_CAMPAIGN_SUBSCRIPTION"
);
export const getActiveCampaignSubscriptionPromise = promisifyRoutine(
  getActiveCampaignSubscription
);

export const getAllUserCampaignSubscriptions = createRoutine(
  "@@subscriptions/GET_ALL_USER_CAMPAIGN_SUBSCRIPTION"
);
export const getAllUserCampaignSubscriptionPromise = promisifyRoutine(
  getAllUserCampaignSubscriptions
);

export const getActiveOrganisationSubscription = createRoutine(
  "@@subscriptions/GET_ACTIVE_ORGANISATION_SUBSCRIPTION"
);
export const getActiveOrganisationSubscriptionPromise = promisifyRoutine(
  getActiveOrganisationSubscription
);

export const getUserSubscriptionByCampaignId = createRoutine(
  "@@subscriptions/GET_USER_SUBSCRIPTION_BY_CAMPAIGN_ID"
);
export const getUserSubscriptionByCampaignIdPromise = promisifyRoutine(
  getUserSubscriptionByCampaignId
);

export const extendCampaign = createRoutine("@@subscriptions/EXTEND_CAMPAIGN");
export const extendCampaignPromise = promisifyRoutine(extendCampaign);

export const changeSubscription = createRoutine(
  "@@subscriptions/CHANGE_SUBSCRIPTION"
);
export const changeSubscriptionPromise = promisifyRoutine(changeSubscription);

export const setFlowPlansBillingFrequency = createRoutine<string>(
  "@@subscriptions/SET_FLOW_PLANS_BILLING_FREQUENCY"
);
export const setFlowPlansBillingFrequencyPromise: (payload: string) => Promise<any> = promisifyRoutine(
  setFlowPlansBillingFrequency
);

export const calculateProRata = createRoutine("@@subscriptions/CALCULATE_PRO_RATA");
export const calculateProRataPromise: (payload: CalculateProRataPayload) => Promise<any> = promisifyRoutine(calculateProRata);

export const setProRata = createRoutine("@@subscriptions/SET_PRO_RATA");
export const setProRataPromise: (payload: number) => Promise<any> = promisifyRoutine(setProRata);

export const getSubscriptionByPurchaseId = createRoutine("@@subscriptions/GET_SUBSCRIPTION_BY_PURCHASE_ID");
export const getSubscriptionByPurchaseIdPromise: (payload: string) => Promise<any> = promisifyRoutine(getSubscriptionByPurchaseId);

export const getSubscriptionById = createRoutine("@@subscriptions/GET_SUBSCRIPTION_BY_ID");
export const getSubscriptionByIdPromise: (payload: string) => Promise<any> = promisifyRoutine(getSubscriptionById);

export const patchSubscription = createRoutine<Partial<SubscriptionState>>("@@subscriptions/PATCH");
export const patchSubscriptionPromise: (payload: Partial<SubscriptionState>) => Promise<any> = promisifyRoutine(patchSubscription);

export const createSubscription = createRoutine<CreateSubscriptionPayload>("@@subscriptions/CREATE_SUBSCRIPTION");
export const createSubscriptionPromise: (payload: CreateSubscriptionPayload) => Promise<any> = promisifyRoutine(createSubscription);

export const masterCampaignCheckout = createRoutine<MasterCampaignCheckoutPayload>("@@subscriptions/MASTER_CAMPAIGN_CHECKOUT");
export const masterCampaignCheckoutPromise: (payload: MasterCampaignCheckoutPayload) => Promise<any> = promisifyRoutine(masterCampaignCheckout);
