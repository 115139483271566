import produce from "immer";
import moment from "moment";
import { get, isArray, isEmpty, omit } from "lodash";
import { Reducer } from "redux";
import { getOrCreatePropAtPath } from "../../utils/general";
import { getMaxRadius } from "../../utils/numberFormatter";
import { getCampaignCountryBudget } from "../../utils/socialMedia";

import { logout } from "../auth/routines";
import { FlowBrandOrFuel } from "./";
import {
  addFlowCampaignStepper,
  createCampaign,
  editCampaign,
  fetchAdAccountCampaigns,
  fetchAdAccounts,
  fetchAdIframeContainer,
  fetchAdvertMetaData,
  fetchCampaignMetadata,
  fetchCampaignModeById,
  fetchFacebookAds,
  fetchFacebookPages,
  fetchFacebookPixels,
  fetchFacebookAudiences,
  fetchFilteredData,
  fetchInstagramAccounts,
  fetchPendingCampaignSubscription,
  fetchProductCatalogue,
  getCampaignTemplates,
  getCredits,
  importFacebookCampaign,
  patchAdCreative,
  patchAdPostDefault,
  patchAgentData,
  setSelectedAccordion,
  setSelectedAdvert,
  setSelectedCampaign,
  updateCampaignStatus,
  updateFacebookCampaign,
  uploadAdCreative,
  uploadCreativeVideoThumbnail,
  addNewAd,
  setAddNewAdFlag,
  patchSelectedFlowCampaign,
  patchTargeting,
  patchTemplateRender,
  patchFieldsToMap,
  patchDashboardCampaignsQuery,
  upsertPublishedCampaign,
  addToDashboardKpiCounts,
  getAdTemplateById,
  setSeenCampaign,
  patchSocial,
  renderTemplate,
  saveDraftCampaignStep,
  campaignCheckout,
  getLinkedFacebookPagesByUserToken,
  linkSelectedClientFacebookPages,
  generateCreativeImage,
  getTargetingTemplateById,
  fetchCampaignDetails,
} from "./routines";
import {
  AdCreative,
  CreativeTypes,
  FieldsToMap,
  SelectedFlowCampaign,
  SocialState,
  Template,
  TemplateRender,
  CampaignSteps,
  DashboardCampaignsQuery,
  CampaignGoalTypes,
  DashboardKpiCounts,
  FlowCampaignTypesEnum,
} from "./types";
import { updateFieldsToMap } from "../../utils/campaign.helper";
import { newFieldsToMap } from "../../utils/campaign.defaults";

const initialState: SocialState = {
  errors: undefined,
  createOrUpdateCampaignLoading: false,
  listLoading: false,
  dashboardKPILoading: false,
  facebookAdsLoading: false,
  iframeContainerLoading: false,
  advertMetaDataLoading: false,
  creditsLoading: false,
  templatesLoading: false,
  advertMetaData: undefined,
  adAccounts: [],
  facebookPages: [],
  instagramAccounts: [],
  productCatalogue: [],
  facebookPixels: [],
  facebookAudiences: [],
  dashboardKpiCounts: undefined,
  facebookAds: undefined,
  adPreviewIframeContainer: undefined,
  adAccountCampaigns: [],
  isFacebookCampaignImport: false,
  campaigns: undefined,
  selectedAd: undefined,
  addCreateFlow: undefined,
  selectedFlowCampaign: undefined,
  credits: undefined,
  campaignTemplates: undefined,
  campaignMetadataLoading: false,
  uploadingAdCreative: false,
  uploadingCreativeVideoThumbnail: false,
  campaignMode: undefined,
  pendingSubscriptionLoading: false,
  isAddNewAd: false,
  flowFuelCount: 0,
  flowBrandCount: 0,
  seenCampaigns: {},
  isRenderingTemplate: false,
  selectedProperties: [],
  initializingCampaign: false,
  clientLinkedFacebookPages: [],
  isGeneratingCreativeImage: false,
  selectedImages: [],
  getAdTemplateByIdLoading: false,
  getTargetingTemplateByIdLoading: false,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // region Trigger

    case updateCampaignStatus.TRIGGER:
    case updateFacebookCampaign.TRIGGER:
    case importFacebookCampaign.TRIGGER:
    case fetchAdAccountCampaigns.TRIGGER:
    case fetchFacebookPixels.TRIGGER:
    case fetchFacebookAudiences.TRIGGER:
    case fetchProductCatalogue.TRIGGER:
    case fetchInstagramAccounts.TRIGGER:
    case fetchFacebookPages.TRIGGER:
    case fetchAdAccounts.TRIGGER:
    case getLinkedFacebookPagesByUserToken.TRIGGER:
    case linkSelectedClientFacebookPages.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    case fetchFilteredData.TRIGGER: {
      return {
        ...state,
        dashboardKPILoading: !get(action, "payload.isPagination"),
        errors: undefined,
      };
    }

    case fetchFacebookAds.TRIGGER: {
      return {
        ...state,
        facebookAdsLoading: true,
        facebookAds: undefined,
        errors: undefined,
      };
    }
    case fetchAdvertMetaData.TRIGGER: {
      return {
        ...state,
        advertMetaDataLoading: true,
        advertMetaData: undefined,
        errors: undefined,
      };
    }

    case fetchCampaignDetails.TRIGGER: {
      return {
        ...state,
        campaignDetailsLoading: true,
        campaignDetails: [],
        errors: undefined,
      };
    }

    case fetchAdIframeContainer.TRIGGER: {
      return { ...state, iframeContainerLoading: true, errors: undefined };
    }

    case setSelectedAdvert.TRIGGER: {
      return { ...state, selectedAd: undefined, errors: undefined };
    }

    case setSelectedAccordion.TRIGGER: {
      const campaigns = get(state, "campaigns", []);
      Object.keys(campaigns).forEach((key) => {
        get(campaigns, `${key}`, []).forEach((item: FlowBrandOrFuel) => {
          item.isExpanded = false;
        });
      });
      return { ...state, campaigns };
    }

    case fetchCampaignModeById.TRIGGER:
    case setSelectedCampaign.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case getCredits.TRIGGER: {
      return {
        ...state,
        credits: undefined,
        creditsLoading: true,
        errors: undefined,
      };
    }

    case getCampaignTemplates.TRIGGER: {
      return {
        ...state,
        campaignTemplates: undefined,
        listLoading: true,
        errors: undefined,
      };
    }

    case createCampaign.TRIGGER: {
      return {
        ...state,
        createOrUpdateCampaignLoading: true,
        errors: undefined,
      };
    }

    case editCampaign.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    case fetchCampaignMetadata.TRIGGER: {
      return { ...state, campaignMetadataLoading: true, errors: undefined };
    }

    case uploadAdCreative.TRIGGER: {
      return { ...state, uploadingAdCreative: true, errors: undefined };
    }

    case uploadCreativeVideoThumbnail.TRIGGER: {
      return {
        ...state,
        uploadingCreativeVideoThumbnail: true,
        errors: undefined,
      };
    }

    case patchAdCreative.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case fetchPendingCampaignSubscription.TRIGGER: {
      return { ...state, pendingSubscriptionLoading: true, errors: undefined };
    }

    case addNewAd.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    case setAddNewAdFlag.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case renderTemplate.TRIGGER: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = undefined;
          draft.isRenderingTemplate = true;
        }
      );

      return newDerivedState;
    }

    case saveDraftCampaignStep.TRIGGER:
    case campaignCheckout.TRIGGER: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = undefined;
          draft.createOrUpdateCampaignLoading = true;
        }
      );

      return newDerivedState;
    }

    case generateCreativeImage.TRIGGER: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = undefined;
          draft.isGeneratingCreativeImage = true;
        }
      );

      return newDerivedState;
    }

    case getAdTemplateById.TRIGGER: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = undefined;
          draft.getAdTemplateByIdLoading = true;
        }
      );

      return newDerivedState;
    }

    case getTargetingTemplateById.TRIGGER: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = undefined;
          draft.getTargetingTemplateByIdLoading = true;
        }
      );

      return newDerivedState;
    }

    //#endregion

    //#region Success
    case fetchAdAccounts.SUCCESS: {
      return {
        ...state,
        adAccounts: action.payload.adAccounts,
      };
    }

    case fetchFacebookPages.SUCCESS: {
      return {
        ...state,
        facebookPages: action.payload.facebookPages,
      };
    }
    case fetchInstagramAccounts.SUCCESS: {
      return {
        ...state,
        instagramAccounts: action.payload.instagramAccounts,
      };
    }
    case fetchProductCatalogue.SUCCESS: {
      return {
        ...state,
        productCatalogue: action.payload.productCatalogue,
      };
    }
    case fetchFacebookPixels.SUCCESS: {
      return {
        ...state,
        facebookPixels: action.payload.facebookPixels,
      };
    }
    case fetchFacebookAudiences.SUCCESS: {
      return {
        ...state,
        facebookAudiences: action.payload.facebookAudiences,
      };
    }

    case fetchFilteredData.SUCCESS: {
      const filteredData = get(action.payload, "filteredData", undefined);

      const campaignsResult = omit(filteredData, [
        "dashboardKpiCounts",
        "flowFuelCount",
        "flowBrandCount",
      ]);

      const flowfuel: any[] = get(campaignsResult, "flowfuel", []);
      const flowbrand: any[] = get(campaignsResult, "flowbrand", []);

      Object.keys(campaignsResult).forEach((key) => {
        get(campaignsResult, `${key}`, []).forEach((item: FlowBrandOrFuel) => {
          item.isExpanded = false;
        });
      });

      const flowCampaignType = get(action, "payload.flowCampaignType");

      let campaigns = state.campaigns;
      let flowFuelCount = state.flowFuelCount || 0;
      let flowBrandCount = state.flowBrandCount || 0;
      if (isEmpty(flowCampaignType)) {
        campaigns = { flowfuel, flowbrand };
        flowFuelCount = get(filteredData, "flowFuelCount", 0);
        flowBrandCount = get(filteredData, "flowBrandCount", 0);
      } else if (campaigns && flowCampaignType === CampaignGoalTypes.FlowFuel) {
        Object.assign(campaigns, { flowfuel });
        flowFuelCount = get(filteredData, "flowFuelCount", 0);
      } else if (
        campaigns &&
        flowCampaignType === CampaignGoalTypes.FlowBrand
      ) {
        Object.assign(campaigns, { flowbrand });
        flowBrandCount = get(filteredData, "flowBrandCount", 0);
      }

      return {
        ...state,
        dashboardKpiCounts: get(
          action,
          "payload.filteredData.dashboardKpiCounts",
          undefined
        ),
        campaigns,
        flowFuelCount,
        flowBrandCount,
      };
    }

    case fetchAdAccountCampaigns.SUCCESS: {
      return {
        ...state,
        adAccountCampaigns: action.payload.adAccountCampaigns,
      };
    }
    case importFacebookCampaign.SUCCESS: {
      return {
        ...state,
        isFacebookCampaignImport: action.payload,
      };
    }

    case setSelectedAccordion.SUCCESS: {
      const campaigns = get(state, "campaigns", []);
      const shouldClose = get(action, "payload.shouldClose", false);
      Object.keys(campaigns).forEach((key) => {
        get(campaigns, `${key}`, []).forEach((item: FlowBrandOrFuel) => {
          if (item.id === get(action, "payload.campainId") && !shouldClose) {
            item.isExpanded = true;
          }
        });
      });
      return {
        ...state,
        campaigns,
      };
    }

    case fetchFacebookAds.SUCCESS: {
      const facebookAds = action.payload;
      return {
        ...state,
        facebookAds,
      };
    }
    case setSelectedAdvert.SUCCESS: {
      const selectedAd = action.payload;
      return {
        ...state,
        selectedAd,
      };
    }

    case fetchAdvertMetaData.SUCCESS: {
      return {
        ...state,
        advertMetaData: action.payload.metaData,
      };
    }

    case fetchCampaignDetails.SUCCESS: {
      return {
        ...state,
        campaignDetails: get(action.payload, "campaignDetails", []),
        campaignDetailsLoading: false,
      };
    }

    case updateFacebookCampaign.SUCCESS: {
      const campaignId = get(action, "payload.result.facebookcampaignid", "");
      const flowcampaigntype = get(
        action,
        "payload.result.flowcampaigntype",
        ""
      );
      const campaignGoal = get(
        action,
        "payload.result.campaignmetadata.goal",
        0
      );
      const campaignCommission = get(
        action,
        "payload.result.campaignmetadata.campaigncommission",
        0
      );
      const flowFuels = get(state, "campaigns.flowfuel", []);
      const flowBrands = get(state, "campaigns.flowbrand", []);
      let index = flowFuels.findIndex(
        (campaigns: any) => campaigns.id === campaignId
      );

      let isFuel = index >= 0;
      if (!isFuel) {
        index = flowBrands.findIndex(
          (campaigns: any) => campaigns.id === campaignId
        );
      }
      const newArray = isFuel ? [...flowFuels] : [...flowBrands];
      // assign the values
      newArray[index].goalDescription = campaignGoal;
      newArray[index].commission = parseInt(campaignCommission);
      newArray[index].flowCampaignType = flowcampaigntype;
      const newCampaign = { ...state.campaigns };

      if (isFuel) {
        newCampaign.flowfuel = newArray;
      } else {
        newCampaign.flowbrand = newArray;
      }

      return {
        ...state,
        campaigns: newCampaign,
      };
    }

    case fetchAdIframeContainer.SUCCESS: {
      const iframeContainer = action.payload.data;
      const xmlDoc = iframeContainer.adPreviewIframeContainer;
      const replacedString = xmlDoc.replace(/\\/g, "").replace("amp;", "");
      const adPreviewIframeContainer = replacedString
        .split('scr="')
        .pop()
        .split('"')[1];
      const newIframeContainer = {
        adPreviewIframeContainer: adPreviewIframeContainer,
      };
      return {
        ...state,
        iframeContainer: newIframeContainer,
      };
    }

    case addFlowCampaignStepper.SUCCESS: {
      return { ...state, addCreateFlow: action.payload, loading: false };
    }

    case setSelectedCampaign.SUCCESS: {
      const fieldsToMap = get(
        state,
        "selectedFlowCampaign.selectedTemplate.fieldsToMap",
        []
      );
      const newArray = [...fieldsToMap];
      const templateRender = action.payload.templateRender;
      if (!isEmpty(templateRender)) {
        let isUpdated = false;
        Object.keys(templateRender).forEach(function eachKey(key) {
          newArray.map((fieldsToMap) => {
            if (fieldsToMap.id === key.toString()) {
              fieldsToMap.value = templateRender[key];
              isUpdated = true;
            }
            return [];
          });
          if (!isUpdated && !isEmpty(templateRender.agentData)) {
            Object.keys(templateRender.agentData).forEach(function eachKey(
              key
            ) {
              newArray.map((fieldsToMap) => {
                if (fieldsToMap.id === key.toString()) {
                  fieldsToMap.value = templateRender.agentData[key];
                  isUpdated = true;
                }
                return [];
              });
            });
          } else if (!isUpdated && !isEmpty(templateRender.facebook)) {
            Object.keys(templateRender.facebook).forEach(function eachKey(key) {
              newArray.map((fieldsToMap) => {
                if (fieldsToMap.id === key.toString()) {
                  fieldsToMap.value = templateRender.facebook[key];
                  isUpdated = true;
                }
                return [];
              });
            });
          }
        });
      }

      const deletedFlag = get(action, "payload.isDeleted", false);
      if (deletedFlag) {
        return {
          ...state,
          selectedFlowCampaign: undefined,
        };
      } else {
        return {
          ...state,
          selectedFlowCampaign: {
            ...state.selectedFlowCampaign,
            ...action.payload,
          },
        };
      }
    }

    case getCredits.SUCCESS: {
      return {
        ...state,
        credits: action.payload,
        creditsLoading: false,
      };
    }

    case getCampaignTemplates.SUCCESS: {
      return {
        ...state,
        campaignTemplates: action.payload,
      };
    }

    case createCampaign.SUCCESS: {
      const campaignFlowStep = get(action.payload, "campaignFlowStep", "");
      const campaignLocalId = get(action.payload, "campaignLocalId", "");
      if (campaignFlowStep !== CampaignSteps.Summary) {
        return {
          ...state,
          selectedFlowCampaign: {
            ...state.selectedFlowCampaign,
            campaignLocalId,
          },
        };
      }
      return state;
    }

    case editCampaign.SUCCESS:
    case updateCampaignStatus.SUCCESS: {
      return {
        ...state,
      };
    }

    case fetchCampaignMetadata.SUCCESS: {
      const creativeTypesEnum: CreativeTypes = get(
        action,
        "payload.creativeTypesEnum",
        {}
      );
      const step = get(action, "payload.editMode", "");
      let adCreative: AdCreative = {},
        targeting = {},
        templateRender = {};
      let selectedTemplate = null,
        customCreative = null;
      let facebookPageId = "",
        instagramPageId = "";
      const adAccountId = get(action, "payload.data.adAccountId", "");
      const agentId = get(action, "payload.data.agentId", "");
      const localeSettings = get(action, "payload.localeSettings", {});
      let remainingRadiusTotal = getMaxRadius(localeSettings);
      let custom_locations;
      let selectedBrandObjectiveType = "";
      let selectedBrandObjectiveTypeUrl = "";
      let selectedFlowFuelObjectiveType = "";
      let selectedFlowFuelObjectiveTypeUrl = "";
      let fieldsToMap: FieldsToMap[] = [];
      let agentData = {};
      let agent;
      let backgroundImageField;
      let bragSituationField;
      let locationField;
      let dateAndTimeField;
      let offerField;
      let productField;
      let storeDetailField;
      let discountPercentageField;
      let activeAd;

      const agentDataSet = get(action, "payload.agentDataSet") || [];

      fieldsToMap = get(action, "payload.data.fieldsToMap", []).map(
        (field: FieldsToMap) => ({
          ...field,
          value: field.value || "",
        })
      );
      if (step === CampaignSteps.Summary) {
        const ads =
          get(action, "payload.data.campaignlocaldata.adsets[0].ads") || [];

        if (isArray(ads)) activeAd = ads.find((ad) => !!ad.isActive);

        fieldsToMap = get(activeAd, "fieldsToMap", []).map(
          (field: FieldsToMap) => ({
            ...field,
            value: field.value || "",
          })
        );
      }

      const _newFieldsToMap: FieldsToMap[] = newFieldsToMap
        .map((fieldId) =>
          fieldsToMap.find((f: FieldsToMap) => f.id === fieldId)
        )
        .filter((fieldToMap) => fieldToMap !== undefined) as FieldsToMap[];

      switch (step) {
        case CampaignSteps.AdCreative: {
          selectedBrandObjectiveType = get(
            action,
            "payload.data.selectedBrandObjectiveType",
            ""
          );
          selectedBrandObjectiveTypeUrl = get(
            action,
            "payload.data.selectedBrandObjectiveTypeUrl",
            ""
          );
          selectedFlowFuelObjectiveType = get(
            action,
            "payload.data.selectedFlowFuelObjectiveType",
            ""
          );
          selectedFlowFuelObjectiveTypeUrl = get(
            action,
            "payload.data.selectedFlowFuelObjectiveTypeUrl",
            ""
          );

          adCreative = {
            ...get(action, "payload.data", {}),
            creativeName: "creative 1",
            name: get(action, "payload.data.headline", ""),
            fieldsToMap,
            creativeImageUrl: "",
            creativeImageBlob: undefined,
          };
          if (adCreative.fieldsToMap && adCreative.fieldsToMap.length > 0) {
            // template
            selectedTemplate = {
              _id: get(action, "payload.data.templateId"),
              type: adCreative.type || "",
              title: adCreative.title || "",
              description: adCreative.templateDescription || "",
              htmlURL: adCreative.htmlContent || "",
              thumbnail: adCreative.thumbnail || "",
              fieldsToMap,
            };
          } else if (
            !adCreative.fieldsToMap ||
            adCreative.fieldsToMap.length < 1
          ) {
            // custom
            customCreative = {
              creativeType: adCreative.videoUrl
                ? creativeTypesEnum.VIDEO
                : creativeTypesEnum.IMAGE,
              creativeUrl: adCreative.videoUrl || adCreative.htmlContent,
              videoThumbnailUrl: adCreative.videoUrl
                ? adCreative.thumbnail
                : null,
            };

            if (customCreative.creativeType === creativeTypesEnum.IMAGE)
              adCreative.creativeImageUrl = customCreative.creativeUrl;
          }

          facebookPageId = get(action, "payload.data.facebookPageId", "");
          instagramPageId = get(action, "payload.data.instagramPageId", "");
          const facebookAccounts = get(
            action,
            "payload.organisation.settings.socialAssetConfiguration.facebook.facebookpage",
            []
          );
          const facebookObject = facebookAccounts.find(
            (acc: any) => acc.pageId === facebookPageId
          );
          templateRender = {
            facebook: facebookObject,
          };

          let campaignType = get(selectedTemplate, "type", "");
          backgroundImageField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "backgroundImage"
          );
          bragSituationField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "bragSituation"
          );
          const agentEmailField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "agentEmail"
          );
          locationField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "location"
          );

          agent = agentDataSet.find(
            (agent: any) =>
              get(agent, "contact.email") === get(agentEmailField, "value", "")
          );

          dateAndTimeField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "dateAndTime"
          );

          offerField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "offer"
          );

          productField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "product"
          );

          storeDetailField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "storeDetail"
          );

          discountPercentageField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "discountPercentage"
          );

          if (customCreative && !selectedTemplate) {
            if (adCreative.agentId && adCreative.propertyId) {
              templateRender = {
                ...templateRender,
                agentData: { selectedProperty: null },
              };
            } else if (adCreative.agentId) {
              agent = agentDataSet.find(
                (agent: any) => get(agent, "value") === adCreative.agentId
              );

              templateRender = {
                ...templateRender,
                agentData: { defaultAgent: agent },
              };
            }
          } else if (selectedTemplate && !customCreative) {
            switch (campaignType) {
              case "Sold Stock":
              case "Promote Listing":
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  selectedProperty: null,
                };
                templateRender = {
                  ...templateRender,
                  agentData,
                  bragSituation: get(bragSituationField, "value", ""),
                  location: get(locationField, "value", ""),
                  dateAndTime: get(dateAndTimeField, "value", ""),
                  offer: get(offerField, "value", ""),
                  product: get(productField, "value", ""),
                  storeDetail: get(storeDetailField, "value", ""),
                  discountPercentage: get(discountPercentageField, "value", ""),
                };
                for (const field of _newFieldsToMap) {
                  templateRender[field.id] = field.value;
                }
                break;

              case "Testimonial":
                const testimonialText1Field = fieldsToMap.find(
                  (field: FieldsToMap) => field.id === "testimonialText1"
                );
                const testimonialText2Field = fieldsToMap.find(
                  (field: FieldsToMap) => field.id === "testimonialText2"
                );
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  defaultAgent: agent,
                };
                templateRender = {
                  ...templateRender,
                  agentData,
                  testimonialText1: get(testimonialText1Field, "value", ""),
                  testimonialText2: get(testimonialText2Field, "value", ""),
                };
                break;

              default:
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  defaultAgent: agent,
                };

                templateRender = {
                  ...templateRender,
                  agentData,
                  bragSituation: get(bragSituationField, "value", ""),
                  location: get(locationField, "value", ""),
                };
                break;
            }
          }

          break;
        }
        case CampaignSteps.Targeting: {
          custom_locations = get(
            action,
            "payload.data.geoLocations.customLocations",
            []
          );
          for (const loc of custom_locations) {
            remainingRadiusTotal -= loc.radius;
          }

          targeting = {
            ...get(action, "payload.data", {}),
            age_min: get(action, "payload.data.agemin", ""),
            age_max: get(action, "payload.data.agemax", ""),
            geo_locations: {
              custom_locations,
              location_types: ["home", "recent"],
            },
            remainingRadiusTotal,
          };
          break;
        }
        case CampaignSteps.Summary: {
          adCreative = {
            ...activeAd,
            creativeName: "creative 1",
            name: get(activeAd, "headline") || "",
            fieldsToMap,
          };

          if (adCreative.fieldsToMap && adCreative.fieldsToMap.length > 0) {
            // template
            selectedTemplate = {
              type: adCreative.type || "",
              title: adCreative.title || "",
              description: adCreative.templateDescription || "",
              htmlURL: adCreative.htmlContent || "",
              thumbnail: adCreative.thumbnail || "",
              fieldsToMap,
            };
          } else if (
            !adCreative.fieldsToMap ||
            adCreative.fieldsToMap.length < 1
          ) {
            // custom
            customCreative = {
              creativeType: adCreative.videoUrl
                ? creativeTypesEnum.VIDEO
                : creativeTypesEnum.IMAGE,
              creativeUrl: adCreative.videoUrl || adCreative.htmlContent,
              videoThumbnailUrl: adCreative.videoUrl
                ? adCreative.thumbnail
                : null,
            };
          }
          facebookPageId = get(action, "payload.data.facebookPageId", "");
          instagramPageId = get(action, "payload.data.instagramPageId", "");

          backgroundImageField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "backgroundImage"
          );
          bragSituationField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "bragSituation"
          );
          locationField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "location"
          );

          dateAndTimeField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "dateAndTime"
          );

          offerField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "offer"
          );

          productField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "product"
          );

          storeDetailField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "storeDetail"
          );

          discountPercentageField = fieldsToMap.find(
            (field: FieldsToMap) => field.id === "discountPercentage"
          );

          if (customCreative && !selectedTemplate) {
            if (adCreative.agentId && adCreative.propertyId) {
              templateRender = {
                ...templateRender,
                agentData: { selectedProperty: null },
              };
            } else if (adCreative.agentId) {
              agent = agentDataSet.find(
                (agent: any) => get(agent, "value") === adCreative.agentId
              );
              templateRender = {
                ...templateRender,
                agentData: { defaultAgent: agent },
              };
            }
          } else if (selectedTemplate && !customCreative) {
            let campaignType = get(selectedTemplate, "type", "");
            switch (campaignType) {
              case "Sold Stock":
              case "Promote Listing":
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  selectedProperty: null,
                };
                templateRender = {
                  ...templateRender,
                  agentData,
                  bragSituation: get(bragSituationField, "value", ""),
                  location: get(locationField, "value", ""),
                  dateAndTime: get(dateAndTimeField, "value", ""),
                  offer: get(offerField, "value", ""),
                  product: get(productField, "value", ""),
                  storeDetail: get(storeDetailField, "value", ""),
                  discountPercentage: get(discountPercentageField, "value", ""),
                };
                for (const field of _newFieldsToMap) {
                  templateRender[field.id] = field.value;
                }
                break;

              case "Testimonial":
                const testimonialText1Field = fieldsToMap.find(
                  (field: FieldsToMap) => field.id === "testimonialText1"
                );
                const testimonialText2Field = fieldsToMap.find(
                  (field: FieldsToMap) => field.id === "testimonialText2"
                );
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  defaultAgent: agent,
                };
                templateRender = {
                  ...templateRender,
                  agentData,
                  testimonialText1: get(testimonialText1Field, "value", ""),
                  testimonialText2: get(testimonialText2Field, "value", ""),
                };
                break;

              default:
                agentData = {
                  backgroundImage: get(backgroundImageField, "value", ""),
                  defaultAgent: agent,
                };
                templateRender = {
                  ...templateRender,
                  agentData,
                  bragSituation: get(bragSituationField, "value", ""),
                  location: get(locationField, "value", ""),
                };
                break;
            }
          }
          //Targeting Region
          custom_locations = get(
            action,
            "payload.data.campaignlocaldata.adsets[0].targeting.geoLocations.customLocations",
            []
          );
          for (const loc of custom_locations) {
            remainingRadiusTotal -= loc.radius;
          }

          targeting = {
            ...get(
              action,
              "payload.data.campaignlocaldata.adsets[0].targeting",
              {}
            ),
            age_min: get(
              action,
              "payload.data.campaignlocaldata.adsets[0].targeting.agemin",
              ""
            ),
            age_max: get(
              action,
              "payload.data.campaignlocaldata.adsets[0].targeting.agemax",
              ""
            ),
            geo_locations: {
              custom_locations,
              location_types: ["home", "recent"],
            },
            remainingRadiusTotal,
          };
          break;
        }
        default:
          break;
      }

      const budgetPerWeekDataset = getCampaignCountryBudget(localeSettings);
      const defaultBudgetFromDataset =
        budgetPerWeekDataset![Object.keys(budgetPerWeekDataset!)[0]];

      const budget = get(defaultBudgetFromDataset, "campaignBudget", 0);
      const days = get(defaultBudgetFromDataset, "days", 3);
      const todayDate = new Date();
      let onceOffInitialEndDate =
        get(action, "payload.data.campaignmetadata.enddate") ||
        todayDate.toISOString();

      //check if flowFuel first
      const isFlowFuel = get(
        state,
        "selectedFlowCampaign.campaignGoalType",
        "FlowFuel"
      ).includes("FlowFuel");

      if (isFlowFuel) {
        const endDateMoment = moment(onceOffInitialEndDate);
        const todayMoment = moment();

        if (endDateMoment.isBefore(todayMoment)) {
          onceOffInitialEndDate = todayMoment.toDate().toISOString();
        }
      }
      return {
        ...state,
        campaignMetadataLoading: false,
        selectedFlowCampaign: {
          ...state.selectedFlowCampaign,
          owner: agentId ? agentId : state.selectedFlowCampaign.owner,
          adAccountId,
          facebookPageId,
          instagramPageId,
          selectedTemplate,
          customCreative,
          templateRender: {
            ...state.selectedFlowCampaign.templateRender,
            ...templateRender,
            isMainDescriptionUpdated: true,
            isHeadlineUpdated: true,
            isDescriptionUpdated: true,
          },
          adCreative: {
            ...state.selectedFlowCampaign.adCreative,
            ...adCreative,
          },
          targeting: {
            ...state.selectedFlowCampaign.targeting,
            ...targeting,
          },
          onceOffInitialWeeks: get(
            action,
            "payload.data.campaignmetadata.onceOffMetaData.weeks",
            0
          ),

          onceOffInitialBudgetPerWeek: Math.floor(
            get(
              action,
              "payload.data.campaignmetadata.onceOffMetaData.budgetPerWeek",
              0
            )
          ),
          onceOffInitialEndDate,
          budget,
          days,
          selectedBrandObjectiveType,
          selectedBrandObjectiveTypeUrl,
          selectedFlowFuelObjectiveType,
          selectedFlowFuelObjectiveTypeUrl,
        },
      };
    }

    case uploadAdCreative.SUCCESS: {
      const creativeTypesEnum: CreativeTypes = get(
        action,
        "payload.creativeTypesEnum",
        {}
      );
      const creativeType: string = get(action, "payload.creativeType", "");
      const creativeUrl: string = get(action, "payload.data", "");

      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          selectedFlowCampaign.adCreative =
            selectedFlowCampaign.adCreative || {};

          if (creativeType === creativeTypesEnum.VIDEO)
            selectedFlowCampaign.adCreative.videoUrl = creativeUrl;
          if (creativeType !== creativeTypesEnum.TEMPLATE) {
            selectedFlowCampaign.adCreative.htmlContent = creativeUrl;
            selectedFlowCampaign.customCreative = {
              ...selectedFlowCampaign.customCreative!,
              adCreativeFile: null,
              creativeUrl,
            };
          }

          if (creativeType !== creativeTypesEnum.VIDEO)
            selectedFlowCampaign.adCreative.creativeImageUrl = creativeUrl;
          if (creativeType === creativeTypesEnum.TEMPLATE)
            selectedFlowCampaign.adCreative.creativeImageBlob = undefined;

          draft.uploadingAdCreative = false;
        }
      );

      return newDerivedState;
    }

    case uploadCreativeVideoThumbnail.SUCCESS: {
      return {
        ...state,
        uploadingCreativeVideoThumbnail: false,
        selectedFlowCampaign: {
          ...(state.selectedFlowCampaign || {}),
          customCreative: {
            ...(state.selectedFlowCampaign.customCreative || {}),
            videoThumbnailBlob: null,
            videoThumbnailUrl: action.payload,
          },
          adCreative: {
            ...(state.selectedFlowCampaign.adCreative || {}),
            thumbnail: action.payload,
          },
        },
      };
    }

    case fetchCampaignModeById.SUCCESS: {
      return {
        ...state,
        campaignMode: action.payload,
      };
    }

    case fetchPendingCampaignSubscription.SUCCESS: {
      return {
        ...state,
        pendingSubscriptionLoading: false,
        selectedFlowCampaign: {
          ...state.selectedFlowCampaign,
          pendingSubscription: get(action, "payload[0]", null),
        },
      };
    }

    case patchAdCreative.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          const adCreative = selectedFlowCampaign.adCreative || {};
          selectedFlowCampaign.adCreative = {
            ...adCreative,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case patchAgentData.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let templateRender: TemplateRender = getOrCreatePropAtPath(
            draft,
            "selectedFlowCampaign.templateRender"
          );
          const agentData = templateRender.agentData || {};
          templateRender.agentData = {
            ...agentData,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case patchAdPostDefault.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let templateRender: TemplateRender = getOrCreatePropAtPath(
            draft,
            "selectedFlowCampaign.templateRender"
          );
          const adPostDefault = templateRender.adpostDefault || {};
          templateRender.adpostDefault = {
            ...adPostDefault,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case addNewAd.SUCCESS: {
      return {
        ...state,
        isAddNewAd: action.payload,
      };
    }

    case setAddNewAdFlag.SUCCESS: {
      return {
        ...state,
        isAddNewAd: action.payload,
      };
    }

    case patchSelectedFlowCampaign.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.selectedFlowCampaign = {
            ...draft.selectedFlowCampaign,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case patchTargeting.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          const targeting = selectedFlowCampaign.targeting || {};
          selectedFlowCampaign.targeting = {
            ...targeting,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case patchTemplateRender.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          const templateRender = selectedFlowCampaign.templateRender || {};
          selectedFlowCampaign.templateRender = {
            ...templateRender,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case patchFieldsToMap.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          // please excuse the repeating code, I tried cleaning but keep running into rabbit hole
          let adCreative: AdCreative = getOrCreatePropAtPath(
            draft,
            "selectedFlowCampaign.adCreative"
          );
          let selectedTemplate: Template = getOrCreatePropAtPath(
            draft,
            "selectedFlowCampaign.selectedTemplate"
          );
          const adCreativeFieldsToMap = adCreative.fieldsToMap || [];
          const templateFieldsToMap = selectedTemplate.fieldsToMap || [];

          const fieldsToUpdate: Partial<FieldsToMap>[] = isArray(action.payload)
            ? action.payload
            : [action.payload];

          updateFieldsToMap(adCreativeFieldsToMap, fieldsToUpdate);
          updateFieldsToMap(templateFieldsToMap, fieldsToUpdate);
        }
      );

      return newDerivedState;
    }

    case patchDashboardCampaignsQuery.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          const dashboardCampaignsQuery: DashboardCampaignsQuery =
            getOrCreatePropAtPath(draft, "dashboardCampaignsQuery");
          draft.dashboardCampaignsQuery = {
            ...dashboardCampaignsQuery,
            ...action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case upsertPublishedCampaign.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          const newCampaignRecord: FlowBrandOrFuel = action.payload;
          const isFlowBrand =
            newCampaignRecord.flowCampaignType ===
            FlowCampaignTypesEnum.FlowBrand;

          const allCampaigns: Record<string, FlowBrandOrFuel[]> =
            getOrCreatePropAtPath(draft, "campaigns");

          let key = null;
          if (isFlowBrand && isArray(allCampaigns.flowbrand)) key = "flowbrand";
          else if (isArray(allCampaigns.flowfuel)) key = "flowfuel";

          if (key) {
            const idx = allCampaigns[key].findIndex((campaign) => {
              return (
                campaign.localCampaignId ===
                  newCampaignRecord.localCampaignId ||
                campaign.id === newCampaignRecord.localCampaignId
              );
            });

            // remove old matching item
            if (idx !== -1) allCampaigns[key].splice(idx, 1);

            // insert at index 1 if first item is a draft
            if (allCampaigns[key][0] && allCampaigns[key][0].isDraft)
              allCampaigns[key].splice(1, 0, newCampaignRecord);
            else allCampaigns[key].unshift(newCampaignRecord); // else insert at index 0
          }
        }
      );

      return newDerivedState;
    }

    case addToDashboardKpiCounts.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          const kpiCountsToAdd: DashboardKpiCounts = action.payload;
          const dashboardKpiCounts: DashboardKpiCounts = getOrCreatePropAtPath(
            draft,
            "dashboardKpiCounts"
          );

          dashboardKpiCounts.buyerLeads += kpiCountsToAdd.buyerLeads;
          dashboardKpiCounts.sellerLeads += kpiCountsToAdd.sellerLeads;
          dashboardKpiCounts.recruitLeads += kpiCountsToAdd.recruitLeads;
          dashboardKpiCounts.campaignsCount += kpiCountsToAdd.campaignsCount;
          dashboardKpiCounts.uniquePeopleReached +=
            kpiCountsToAdd.uniquePeopleReached;
          dashboardKpiCounts.listingsProfileViews +=
            kpiCountsToAdd.listingsProfileViews;
        }
      );

      return newDerivedState;
    }

    case getAdTemplateById.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          selectedFlowCampaign.selectedTemplate = action.payload;
        }
      );

      return newDerivedState;
    }

    case setSeenCampaign.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.seenCampaigns[action.payload] = true;
        }
      );

      return newDerivedState;
    }

    case patchSocial.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          Object.assign(draft, { ...action.payload });
        }
      );

      return newDerivedState;
    }

    case renderTemplate.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          const adCreative = selectedFlowCampaign.adCreative || {};
          selectedFlowCampaign.adCreative = {
            ...adCreative,
            videoUrl: get(action, "payload.videoUrl") || "",
          };
        }
      );

      return newDerivedState;
    }

    case saveDraftCampaignStep.SUCCESS: {
      const campaignFlowStep = get(action.payload, "campaignFlowStep", "");
      const campaignLocalId = get(action.payload, "campaignLocalId", "");

      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");

          if (campaignFlowStep !== CampaignSteps.Summary) {
            selectedFlowCampaign.campaignLocalId = campaignLocalId;
          }
        }
      );

      return newDerivedState;
    }

    case getLinkedFacebookPagesByUserToken.SUCCESS: {
      return {
        ...state,
        clientLinkedFacebookPages: action.payload,
      };
    }

    case generateCreativeImage.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          const adCreative = selectedFlowCampaign.adCreative || {};
          selectedFlowCampaign.adCreative = {
            ...adCreative,
            creativeImageBlob: action.payload,
          };
        }
      );

      return newDerivedState;
    }

    case getTargetingTemplateById.SUCCESS: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          let selectedFlowCampaign: SelectedFlowCampaign =
            getOrCreatePropAtPath(draft, "selectedFlowCampaign");
          selectedFlowCampaign.targetingTemplate = action.payload;
        }
      );

      return newDerivedState;
    }

    //#endregion

    //#region  Failure
    case fetchAdAccounts.FAILURE: {
      return {
        ...state,
        adAccounts: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchFacebookPages.FAILURE: {
      return {
        ...state,
        facebookPages: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchInstagramAccounts.FAILURE: {
      return {
        ...state,
        instagramAccounts: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchProductCatalogue.FAILURE: {
      return {
        ...state,
        productCatalogue: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchFacebookPixels.FAILURE: {
      return {
        ...state,
        facebookPixels: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchFacebookAudiences.FAILURE: {
      return {
        ...state,
        facebookAudiences: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case fetchFilteredData.FAILURE: {
      return {
        ...state,
        dashboardKpiCounts: undefined,
        dashboardKPILoading: false,
        errors: action.payload,
      };
    }
    case fetchAdAccountCampaigns.FAILURE: {
      return {
        ...state,
        adAccountCampaigns: [],
        listLoading: false,
        errors: action.payload,
      };
    }
    case importFacebookCampaign.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case updateFacebookCampaign.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case fetchFacebookAds.FAILURE: {
      return {
        ...state,
        facebookAds: undefined,
        facebookAdsLoading: false,
        errors: action.payload,
      };
    }
    case setSelectedAdvert.FAILURE: {
      return {
        ...state,
        selectedAd: undefined,
        errors: action.payload,
      };
    }

    case fetchAdvertMetaData.FAILURE: {
      return {
        ...state,
        advertMetaData: undefined,
        advertMetaDataLoading: false,
        errors: action.payload,
      };
    }

    case fetchCampaignDetails.FAILURE: {
      return {
        ...state,
        campaignDetails: [],
        campaignDetailsLoading: false,
        errors: action.payload,
      };
    }

    case fetchAdIframeContainer.FAILURE: {
      return {
        ...state,
        iframeContainer: undefined,
        iframeContainerLoading: false,
        errors: action.payload,
      };
    }

    case getCredits.FAILURE: {
      return {
        ...state,
        credits: undefined,
        creditsLoading: false,
        errors: action.payload,
      };
    }

    case getCampaignTemplates.FAILURE: {
      return {
        ...state,
        campaignTemplates: undefined,
        errors: action.payload,
      };
    }

    case createCampaign.FAILURE: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case editCampaign.FAILURE:
    case updateCampaignStatus.FAILURE:
    case linkSelectedClientFacebookPages.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        listLoading: false,
      };
    }

    case fetchCampaignMetadata.FAILURE: {
      return {
        ...state,
        campaignMetadataLoading: false,
        errors: action.payload,
      };
    }

    case uploadAdCreative.FAILURE: {
      return {
        ...state,
        uploadingAdCreative: false,
        errors: action.payload,
      };
    }

    case uploadCreativeVideoThumbnail.FAILURE: {
      return {
        ...state,
        uploadingCreativeVideoThumbnail: false,
        errors: action.payload,
      };
    }

    case fetchCampaignModeById.FAILURE: {
      return {
        ...state,
        campaignMode: undefined,
        errors: action.payload,
      };
    }

    case fetchPendingCampaignSubscription.FAILURE: {
      return {
        ...state,
        pendingSubscriptionLoading: false,
        errors: action.payload,
      };
    }

    case addNewAd.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case setAddNewAdFlag.FAILURE: {
      return {
        ...state,
        isAddNewAd: false,
        errors: action.payload,
      };
    }

    case renderTemplate.FAILURE: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = action.payload;
        }
      );

      return newDerivedState;
    }

    case saveDraftCampaignStep.FAILURE:
    case campaignCheckout.FAILURE: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = action.payload;
        }
      );

      return newDerivedState;
    }

    case getLinkedFacebookPagesByUserToken.FAILURE: {
      return {
        ...state,
        clientLinkedFacebookPages: [],
        listLoading: false,
        errors: action.payload,
      };
    }

    case generateCreativeImage.FAILURE: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = action.payload;
        }
      );

      return newDerivedState;
    }

    case getAdTemplateById.FAILURE:
    case getTargetingTemplateById.FAILURE: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.errors = action.payload;
        }
      );

      return newDerivedState;
    }

    //#endregion

    //#region Fullfill
    case setSelectedCampaign.FULFILL: {
      return { ...state, listLoading: false };
    }

    case fetchAdAccounts.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case fetchFacebookPages.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case fetchInstagramAccounts.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case fetchProductCatalogue.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case fetchFacebookPixels.FULFILL:
    case fetchFacebookAudiences.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case fetchFilteredData.FULFILL: {
      return {
        ...state,
        dashboardKPILoading: false,
      };
    }

    case fetchAdAccountCampaigns.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }
    case importFacebookCampaign.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case updateFacebookCampaign.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case fetchFacebookAds.FULFILL: {
      return {
        ...state,
        facebookAdsLoading: false,
      };
    }

    case fetchAdvertMetaData.FULFILL: {
      return { ...state, advertMetaDataLoading: false };
    }

    case fetchCampaignDetails.FULFILL: {
      return { ...state, campaignDetailsLoading: false };
    }

    case fetchAdIframeContainer.FULFILL: {
      return {
        ...state,
        iframeContainerLoading: false,
      };
    }

    case getCredits.FULFILL: {
      return {
        ...state,
        creditsLoading: false,
      };
    }

    case getCampaignTemplates.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case createCampaign.FULFILL: {
      return {
        ...state,
        createOrUpdateCampaignLoading: false,
      };
    }

    case logout.FULFILL: {
      return initialState;
    }

    case editCampaign.FULFILL:
    case updateCampaignStatus.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case fetchCampaignMetadata.FULFILL: {
      return {
        ...state,
        campaignMetadataLoading: false,
      };
    }

    case uploadAdCreative.FULFILL: {
      return {
        ...state,
        uploadingAdCreative: false,
      };
    }

    case uploadCreativeVideoThumbnail.FULFILL: {
      return {
        ...state,
        uploadingCreativeVideoThumbnail: false,
      };
    }

    case fetchCampaignModeById.FULFILL: {
      return state;
    }
    case patchAdCreative.FULFILL: {
      return { ...state };
    }

    case fetchPendingCampaignSubscription.FULFILL: {
      return {
        ...state,
        pendingSubscriptionLoading: false,
      };
    }

    case addNewAd.FULFILL:
    case getLinkedFacebookPagesByUserToken.FULFILL:
    case linkSelectedClientFacebookPages.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case setAddNewAdFlag.FULFILL: {
      return {
        ...state,
      };
    }

    case renderTemplate.FULFILL: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.isRenderingTemplate = false;
        }
      );

      return newDerivedState;
    }

    case saveDraftCampaignStep.FULFILL:
    case campaignCheckout.FULFILL: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.createOrUpdateCampaignLoading = false;
        }
      );

      return newDerivedState;
    }

    case generateCreativeImage.FULFILL: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.isGeneratingCreativeImage = false;
        }
      );

      return newDerivedState;
    }

    case getAdTemplateById.FULFILL: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.getAdTemplateByIdLoading = false;
        }
      );

      return newDerivedState;
    }

    case getTargetingTemplateById.FULFILL: {
      const newDerivedState = produce<SocialState>(
        state,
        (draft: SocialState) => {
          draft.getTargetingTemplateByIdLoading = false;
        }
      );

      return newDerivedState;
    }

    //#endregion
    default: {
      return state;
    }
  }
};

export { reducer as socialReducer };
