import { push, replace, RouterState } from "connected-react-router";
import jwtDecode from "jwt-decode";
import _, { cloneDeep, get, isEmpty } from "lodash";
import { AnyAction } from "redux";
import { Action } from "redux-actions";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import queryString from "query-string";

import { getQueryParamsFromLocation } from "../../utils/url.helper";
import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { getPrivileges, queryFromObject } from "../../utils/general";
import { logEvent } from "../analytics";
import { unsetDeeplink } from "../deeplink";
import { fetchActiveEnums } from "../defaults";
import { enqueueSnackbar } from "../notifications";
import {
  Organisation,
  OrganisationState,
  patchOrganisationState,
  postAuthOrgInit,
  PostAuthOrgInitResponsePayload,
} from "../organisation";
import {
  authenticate,
  forgotPassword,
  linkOutLogin,
  login,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  patchUserFbSocial,
  register,
  requestOTP,
  resetPassword,
  setPrivilege,
  updateUser,
  uploadProfileImage,
  validateToken,
  verifyOTP,
  verifyUserByEmail,
  checkLoginProvider,
  signInWithProvider,
} from "./routines";
import { AuthenticatedUser, LinkOutLoginPayload, UserSocial } from "./types";
import { addGTMDataLayerWithRawData } from '../../utils/GoogleTagManager';

function getQueryParam(queryString: string, key: string): string | null {
  let value = null;
  try {
    const queryParams = new URLSearchParams(queryString);
    value = queryParams.get(key);
  } catch (err) {
    console.warn("Failed to parse query string:", queryString, err);
  }
  return value;
}

// Get current role
function* getCurrentUser() {
  const { auth } = yield select((state: ApplicationState) => state);

  const roles = _.get(auth, "user.roles", []);
  const isAdmin = roles.includes("flow-admin");
  const role = isAdmin ? "admin/" : "";
  const admin = _.get(auth, "user._id", undefined);

  return { role, admin, isAdmin };
}

function* handleAuthenticate(action: AnyAction): any {
  try {
    yield put(authenticate.request());

    const token = localStorage.getItem("auth_token");
    if (token) {
      const res = yield call(
        callApi,
        "get",
        `/users/me${
          action.payload.updateLastLogin ? "?updateLastLogin=true" : ""
        }`
      );

      const decodedToken = jwtDecode(token);
      const requireVerification = _.get(
        decodedToken,
        "verification.contactNumber.required",
        false
      );

      if (res.data.status !== "Verified" && requireVerification) {
        yield put(logout.trigger());
      } else {
        yield put(authenticate.success(res.data));
      }
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(authenticate.failure(errorHandler(response)));
    } else {
      yield put(authenticate.failure("An unknown error occured."));
    }
  } finally {
    yield put(authenticate.fulfill());
  }
}

function* handlePrivilege(action: AnyAction) {
  try {
    const { privileges } = action.payload;
    yield put(setPrivilege.request());
    yield put(setPrivilege.success(privileges));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(setPrivilege.failure(errorHandler(response)));
    } else {
      yield put(setPrivilege.failure("An unknown error occured."));
    }
  } finally {
    yield put(setPrivilege.fulfill());
  }
}

function* handleForgotPassword(action: AnyAction) {
  try {
    yield put(forgotPassword.request());

    yield call(callApi, "post", "/auth/forgot", {
      data: action.payload,
    });

    yield put(forgotPassword.success());
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(forgotPassword.failure(errorHandler(response)));
    } else {
      yield put(forgotPassword.failure("An unknown error occured."));
    }
  } finally {
    yield put(forgotPassword.fulfill());
  }
}

function* handleLogin(action: AnyAction): any {
  try {
    yield put(login.request());
    // To call async functions, use redux-saga's `call()`.
    const res = yield call(callApi, "post", "/auth/signin", {
      data: action.payload,
    });

    yield put(login.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(login.failure(errorHandler(response)));
    } else {
      yield put(login.failure("An unknown error occured."));
    }
  } finally {
    yield put(login.fulfill());
  }
}

function* handleLoginWithGoogle(action: AnyAction): any {
  try {
    yield put(loginWithGoogle.request());
    const url = !isEmpty(action.payload.query)
      ? `/auth/google?access_token=${action.payload.accessToken}&register=${
          action.payload.register
        }${queryFromObject(action.payload.query)}`
      : `/auth/google?access_token=${action.payload.accessToken}&register=${action.payload.register}`;
    const res = yield call(callApi, "post", url, {
      data: action.payload.data,
    });
    yield put(register.success(res.data));
    const { userType } = action.payload;

    if (action.payload.register) {
      yield put(
        logEvent({
          data: {
            UserType: userType,
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName}`,
          },
          event: "Completed registration",
          exclude: ["intercom"],
        })
      );
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      if (
        response.status === 409 &&
        response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(loginWithGoogle.failure(errorHandler(response)));
      }
    } else {
      yield put(loginWithGoogle.failure("An unknown error occured."));
    }
  } finally {
    yield put(loginWithGoogle.fulfill());
  }
}

function* handleLoginWithFacebook(action: AnyAction): any {
  try {
    yield put(loginWithFacebook.request());

    const url = !isEmpty(action.payload.query)
      ? `/auth/facebook?access_token=${action.payload.accessToken}&register=${
          action.payload.register
        }${queryFromObject(action.payload.query)}`
      : `/auth/facebook?access_token=${action.payload.accessToken}&register=${action.payload.register}`;
    const res = yield call(callApi, "post", url, {
      data: action.payload.data,
    });

    yield put(register.success(res.data));
    const { userType } = action.payload;

    if (action.payload.register) {
      yield put(
        logEvent({
          data: {
            UserType: userType,
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName}`,
          },
          event: "Completed registration",
          exclude: ["intercom"],
        })
      );
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      if (
        response.status === 409 &&
        response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(loginWithFacebook.failure(errorHandler(response)));
      }
    } else {
      yield put(loginWithFacebook.failure("An unknown error occured."));
    }
  } finally {
    yield put(loginWithFacebook.fulfill());
  }
}

function* handleLogout() {
  try {
    yield put(logout.request());

    // To call async functions, use redux-saga's `call()`.
    yield call(callApi, "get", "/auth/signout");

    localStorage.removeItem("auth_token");

    const { FB } = window;

    if (FB) {
      FB.AppEvents.clearUserID();
    }

    yield put(unsetDeeplink());

    const router: RouterState = yield select(
      (state: ApplicationState) => state.router
    );

    const location = cloneDeep(router.location);
    const state = location.state || {};
    Object.assign(location, {
      state: { ...state, logout: true },
    });
    yield put(replace(location));

    yield put(logout.success());
    // yield put(push("/login", {}));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(logout.failure(errorHandler(response)));
    } else {
      yield put(logout.failure("An unknown error occured."));
    }
  } finally {
    yield put(logout.fulfill());
  }
}

function* handleRegister(action: AnyAction): any {
  try {
    yield put(register.request());
    // To call async functions, use redux-saga's `call()`.
    const { userType } = action.payload;
    const res = yield call(callApi, "post", "/auth/signup", {
      data: action.payload,
    });

    yield put(
      logEvent({
        data: {
          UserType: userType,
          email: res.data.email,
          name: `${res.data.firstName} ${res.data.lastName}`,
        },
        event: "Completed registration",
        exclude: ["intercom"],
      })
    );

    yield put(register.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      if (
        response.status === 409 &&
        response.data.message.includes("duplicate key error")
      ) {
        yield put(
          register.failure(
            "Could not register user. An account with the supplied email already exists."
          )
        );
      } else {
        yield put(register.failure(errorHandler(response)));
      }
    } else {
      yield put(register.failure("An unknown error occured."));
    }
  } finally {
    yield put(register.fulfill());
  }
}

function* handleResetPassword(action: AnyAction) {
  try {
    yield put(resetPassword.request());

    yield call(callApi, "post", `/auth/reset/${action.payload.token}`, {
      data: action.payload.data,
    });

    yield put(resetPassword.success());
    yield put(push("/login"));
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(resetPassword.failure(response.data.message));
    } else if (response) {
      yield put(resetPassword.failure(errorHandler(response)));
    } else {
      yield put(resetPassword.failure("An unknown error occured."));
    }
  } finally {
    yield put(resetPassword.fulfill());
  }
}

function* handleValidateToken(action: AnyAction): any {
  try {
    const res = yield call(callApi, "get", `/auth/reset/${action.payload}`);

    if (res.error) {
      yield put(validateToken.failure(res.error));
    } else {
      yield put(validateToken.success());
    }
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(validateToken.failure(errorHandler(response)));
    } else {
      yield put(validateToken.failure("An unknown error occured."));
    }
  } finally {
    yield put(validateToken.fulfill());
  }
}

function* handleUpdateUser(action: AnyAction): any {
  try {
    yield put(updateUser.request());

    const { role, isAdmin } = yield call(getCurrentUser);
    const { payload } = action;
    const url = isAdmin ? `/${role}users/${payload.id}` : `users`;
    const res = yield call(callApi, "put", url, { data: payload });

    yield put(updateUser.success(res.data));

    yield put(
      enqueueSnackbar({
        message: "Profile was successfully updated",
        options: { variant: "success" },
      })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(updateUser.failure(response.data.message));
    } else if (response) {
      yield put(updateUser.failure(errorHandler(response)));
    } else {
      yield put(updateUser.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateUser.fulfill());
  }
}

function* handleUploadProfileImage(action: AnyAction): any {
  try {
    const { userId, fileData, isFromMembers } = action.payload;
    const formData = new FormData();
    formData.append("file", fileData);

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const { role, isAdmin } = yield call(getCurrentUser);
    const url = isAdmin ? `/${role}users/${userId}/picture` : `/users/picture`;
    const res = yield call(callApi, "post", url, config);
    yield put(uploadProfileImage.success({ user: res.data, isFromMembers }));

    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(uploadProfileImage.failure(response.data.message));
    } else if (response) {
      yield put(uploadProfileImage.failure(errorHandler(response)));
    } else {
      yield put(uploadProfileImage.failure("An unknown error occured."));
    }
  } finally {
    yield put(uploadProfileImage.fulfill());
  }
}

function* handleVerifyOTP(action: AnyAction): any {
  try {
    yield put(verifyOTP.request());

    const res = yield call(callApi, "post", `/auth/verify`, {
      data: action.payload,
    });

    yield put(verifyOTP.success(res.data));
    yield put(
      logEvent({
        event: "User_Verified_Mobile_Success",
      })
    );
  } catch (err) {
    yield put(
      logEvent({
        event: "User_Verified_Mobile_Failed",
      })
    );
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(verifyOTP.failure(response.data.message));
    } else if (response) {
      yield put(verifyOTP.failure(errorHandler(response)));
    } else {
      yield put(verifyOTP.failure("An unknown error occured."));
    }
  } finally {
    yield put(verifyOTP.fulfill());
  }
}

function* handleRequestOTP(): any {
  try {
    yield put(requestOTP.request());

    const res = yield call(callApi, "post", `/auth/verify`);

    yield put(
      enqueueSnackbar({
        message: res.data.message,
        options: { variant: "success" },
      })
    );

    yield put(requestOTP.success(res.data));
    yield put(
      logEvent({
        event: "User_Requested_Mobile_Verification",
      })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(requestOTP.failure(response.data.message));
    } else if (response) {
      yield put(requestOTP.failure(errorHandler(response)));
    } else {
      yield put(requestOTP.failure("An unknown error occured."));
    }
  } finally {
    yield put(requestOTP.fulfill());
  }
}

function* handleShowError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: errorHandler(action.payload),
      options: { variant: "error" },
    })
  );
}

function* postAuth(action: AnyAction): any {
  const isTenant = action.payload.tags.find((tag: any) => {
    return tag.name === "tenant";
  });

  if (isTenant) {
    yield put(push("/noaccess", {}));
    return yield put(logout.trigger());
  } else {
    const { FB } = window;

    if (FB) {
      FB.AppEvents.setUserID(action.payload._id);
    }

    // TODO: uncomment if we bring back verification
    // if (action.payload.status !== "Verified") {
    //   return yield put(push("/verify"));
    // }

    yield put(fetchActiveEnums());

    //#region FetchAndInitializeOrganisationState
    const currentOrgState: OrganisationState = yield select(
      (state: ApplicationState) => state.organisation
    );

    // get organisation query param
    const router: RouterState = yield select(
      (state: ApplicationState) => state.router
    );
    const organisationId = getQueryParam(
      router.location.search,
      "organisation"
    );

    const selectedOrgId =
      get(currentOrgState.subOrganisation, "_id") ||
      get(currentOrgState.organisation, "_id");
    if (
      selectedOrgId === organisationId ||
      (!organisationId && currentOrgState.organisation)
    )
      return;

    const user: AuthenticatedUser = get(action, "payload");

    // init organisation
    yield put(postAuthOrgInit({ organisationId, user }));
    const initOrgResultAction: Action<PostAuthOrgInitResponsePayload | string> = yield take([
      postAuthOrgInit.SUCCESS,
      postAuthOrgInit.FAILURE,
    ]);

    // we now have the organisation
    if (initOrgResultAction.type === postAuthOrgInit.SUCCESS) {
      const responsePayload = initOrgResultAction.payload as PostAuthOrgInitResponsePayload;
      const fetchedOrg = responsePayload.organisation;
      const fetchedSubOrg = responsePayload.subOrganisation;

      if (!organisationId) {
        // set organisation query param if not there
        const queryParams = getQueryParamsFromLocation(router.location);
        queryParams.set("organisation", get(fetchedSubOrg, '_id', fetchedOrg._id));

        const location = cloneDeep(router.location);
        Object.assign(location, { search: queryParams.toString() });
        yield put(replace(location));
      }

      // set privileges
      const privileges = getPrivileges(user, fetchedSubOrg || fetchedOrg);
      yield put(setPrivilege({ privileges }));

      let event = "Authentication Success";
      switch (action.type) {
        case login.SUCCESS:
          event = "Login";
          break;
        case register.SUCCESS:
          event = "Register";
          break;
        case authenticate.SUCCESS:
          event = "Rehydrate/Refresh";
          break;
        default:
          event = "Authentication Success";
          break;
      }

      addGTMDataLayerWithRawData(
        { event, page: router.location.pathname },
        {
          organisation: fetchedSubOrg || fetchedOrg,
          user: action.payload,
        }
      );
    }
    //#endregion FetchAndInitializeOrganisationState
  }
}

function* handleVerfyUserByEmail(action: AnyAction): any {
  try {
    const { email } = action.payload;
    yield put(verifyUserByEmail.request());
    const res = yield call(callApi, "post", `/auth/verify/user`, {
      data: { email },
    });
    yield put(verifyUserByEmail.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response.status === 400) {
      yield put(verifyUserByEmail.failure(response.data.message));
    } else if (response) {
      yield put(verifyUserByEmail.failure(errorHandler(response)));
    } else {
      yield put(verifyUserByEmail.failure("An unknown error occured."));
    }
  } finally {
    yield put(verifyUserByEmail.fulfill());
  }
}

function* handlePatchUserFbSocial(action: Action<Partial<UserSocial>>): any {
  try {
    yield put(patchUserFbSocial.request());
    yield put(patchUserFbSocial.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchUserFbSocial.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchUserFbSocial.fulfill());
  }
}

function* handleLinkOutLogin(action: Action<LinkOutLoginPayload>): any {
  try {
    const res = yield call(
      callApi,
      "get",
      `/linkout/login?${queryString.stringify(action.payload)}`
    );

    const organisations = get(res, "data.organisations") || [];
    const organisationId = get(res, "data.defaultOrganisation", "");
    const selectedOrganisation = organisations.find(
      (org: Organisation) => org._id === organisationId
    );

    if (selectedOrganisation) {
      yield put(
        patchOrganisationState({
          memberOrganisations: organisations,
          organisation: selectedOrganisation,
          members: get(selectedOrganisation, "members", []),
        })
      );
    }

    yield put(linkOutLogin.success(res.data));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(linkOutLogin.failure(errorHandler(response)));
    } else {
      yield put(linkOutLogin.failure("An unknown error occured."));
    }
  } finally {
    yield put(linkOutLogin.fulfill());
  }
}

function* checkProvider(action: Action<AnyAction>): any {
  try {
    yield put(checkLoginProvider.request());

    const res = yield call(callApi, "post", "/auth/check-auth-provider", {
      data: action.payload,
    });

    // redirect to login provider
    const redirectUri = _.get(res, "data.entryPoint");
    if (redirectUri) {
      window.location.replace(
        `${redirectUri}?LoginHint=${_.get(action, "payload.email")}`
      );
      return;
    }

    yield put(
      checkLoginProvider.success({ ...res.data, requestData: action.payload })
    );
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(checkLoginProvider.failure(errorHandler(response)));
    } else {
      yield put(checkLoginProvider.failure("An unknown error occured."));
    }
  } finally {
    yield put(checkLoginProvider.fulfill());
  }
}

function* loginWithProvider(action: Action<AnyAction>): any {
  try {
    yield put(signInWithProvider.request());

    const res = yield call(callApi, "post", "auth/signin-provider", {
      data: action.payload,
    });

    yield put(signInWithProvider.success(res.data));
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(signInWithProvider.failure(errorHandler(response)));
    } else {
      yield put(signInWithProvider.failure("An unknown error occured."));
    }
  } finally {
    yield put(signInWithProvider.fulfill());
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* authenticateWatcher() {
  yield takeEvery(authenticate.TRIGGER, handleAuthenticate);
}

function* handlePrivilegeWatcher() {
  yield takeEvery(setPrivilege.TRIGGER, handlePrivilege);
}

function* forgotPasswordWatcher() {
  yield takeEvery(forgotPassword.TRIGGER, handleForgotPassword);
}

function* loginWatcher() {
  yield takeEvery(login.TRIGGER, handleLogin);
}

function* loginWithGoogleWatcher() {
  yield takeEvery(loginWithGoogle.TRIGGER, handleLoginWithGoogle);
}

function* loginWithFacebookWatcher() {
  yield takeEvery(loginWithFacebook.TRIGGER, handleLoginWithFacebook);
}

function* logoutWatcher() {
  yield takeEvery(logout.TRIGGER, handleLogout);
}

function* registerWatcher() {
  yield takeEvery(register.TRIGGER, handleRegister);
}

function* resetPasswordWatcher() {
  yield takeEvery(resetPassword.TRIGGER, handleResetPassword);
}

function* validateTokenWatcher() {
  yield takeEvery(validateToken.TRIGGER, handleValidateToken);
}

function* updateUserWatcher() {
  yield takeEvery(updateUser.TRIGGER, handleUpdateUser);
}

function* uploadProfileImageWatcher() {
  yield takeEvery(uploadProfileImage.TRIGGER, handleUploadProfileImage);
}

function* requestOTPWatcher() {
  yield takeEvery(requestOTP.TRIGGER, handleRequestOTP);
}

function* verifyOTPWatcher() {
  yield takeEvery(verifyOTP.TRIGGER, handleVerifyOTP);
}

function* verifyUserByEmailWatcher() {
  yield takeEvery(verifyUserByEmail.TRIGGER, handleVerfyUserByEmail);
}

function* handlePatchUserFbSocialWatcher() {
  yield takeEvery(patchUserFbSocial.TRIGGER, handlePatchUserFbSocial);
}

function* linkOutLoginWatcher() {
  yield takeEvery(linkOutLogin.TRIGGER, handleLinkOutLogin);
}

function* checkProviderWatcher() {
  yield takeEvery(checkLoginProvider.TRIGGER, checkProvider);
}

function* loginWithProviderWatcher() {
  yield takeEvery(signInWithProvider.TRIGGER, loginWithProvider);
}

function* showErrorWatcher() {
  yield takeEvery(
    [
      setPrivilege.FAILURE,
      loginWithFacebook.FAILURE,
      loginWithGoogle.FAILURE,
      login.FAILURE,
      register.FAILURE,
      updateUser.FAILURE,
      uploadProfileImage.FAILURE,
    ],
    handleShowError
  );
}

function* postAuthWatcher() {
  yield takeEvery(
    [
      login.SUCCESS,
      register.SUCCESS,
      authenticate.SUCCESS,
      signInWithProvider.SUCCESS,
    ],
    postAuth
  );
}

// We can also use `fork()` here to split our saga into multiple watchers.
export function* authSaga() {
  yield all([
    fork(postAuthWatcher),
    fork(authenticateWatcher),
    fork(forgotPasswordWatcher),
    fork(loginWatcher),
    fork(loginWithGoogleWatcher),
    fork(loginWithFacebookWatcher),
    fork(logoutWatcher),
    fork(registerWatcher),
    fork(resetPasswordWatcher),
    fork(validateTokenWatcher),
    fork(updateUserWatcher),
    fork(uploadProfileImageWatcher),
    fork(requestOTPWatcher),
    fork(verifyOTPWatcher),
    fork(showErrorWatcher),
    fork(handlePrivilegeWatcher),
    fork(verifyUserByEmailWatcher),
    fork(handlePatchUserFbSocialWatcher),
    fork(linkOutLoginWatcher),
    fork(checkProviderWatcher),
    fork(loginWithProviderWatcher),
  ]);
}
